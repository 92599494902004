<template>
  <div class="g07-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchDeleteClick">批量删除</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleDeleteAllClick">全部删除</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      v-if="g07List.length"
      :data="g07List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      :selection="true"
      :row-class-name="getRowClassName"
      keyFiled="g07Guid"
      @select-all-change="handleSelectAllChange"
      @selection-change="handleSelectionChange"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageDisplayCode" width="130" header-align="left" align="left" label="子目号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageName" width="260" header-align="left" align="left" label="子目名称"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="meterageList.pictureNum" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="meterageList.price" width="120" header-align="center" align="center" label="单价（元）"></el-table-column>
        <el-table-column prop="meterageList.pictureAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="第一年度" header-align="center">
        <el-table-column
          width="120"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="工程量">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.firstYearNum">
            </editable-table-cell>
            <span v-else>{{ scope.row.firstYearNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="firstYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="firstYearPercent" width="120" header-align="center" align="center" label="占合同（%）"></el-table-column>
      </el-table-column>
      <el-table-column label="第二年度" header-align="center">
        <el-table-column
          width="120"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="工程量">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.secondYearNum">
            </editable-table-cell>
            <span v-else>{{ scope.row.secondYearNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="secondYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="secondYearPercent" width="120" header-align="center" align="center" label="占合同（%）"></el-table-column>
      </el-table-column>
      <el-table-column label="第三年度" header-align="center">
        <el-table-column prop="thirdYearNum" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="thirdYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="thirdYearPercent" width="120" header-align="center" align="center" label="占合同（%）"></el-table-column>
      </el-table-column>
      <el-table-column v-if="showAddBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" v-if="!scope.row.isLock" class="text-danger far fa-trash-alt operation-button"></i>
            <i v-else class="text-muted far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog
      ref="myDialog"
      title="选择草图"
      size="xl"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <picture-index
        ref="pictureIndex"
        :selection="true"
        >
      </picture-index>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PictureIndex from '@/views/PictureManage/PictureIndex'
import MyElTable from '@/components/MyElTable/MyElTable'
import G07Model from '@/model/G07Model'
import g07Service from '@/services/g07Service'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import config from '@/config'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'

export default {
  name: 'G07Index',
  mixins: [
    PageOperation
  ],
  components: {
    PictureIndex,
    MyElTable,
    MyDialog,
    EditableTableCell,
    MySearchBar
  },
  filters: {
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userTenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && (config.applyIdentities.indexOf(this.userIdentityGuid) > -1 || config.planStatSuperviseTenderGuids.indexOf(this.userTenderGuid) > -1)
    },
    showEditBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.g07List.length) {
        return this.g07List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g07List: [],
      g07Model: new G07Model(),
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: ''
    }
  },
  methods: {
    handleDeleteAllClick () {
      this.$confirm('您确定要全部删除吗？')
        .then(() => {
          g07Service.deleteAll(this.tenderGuid).then(() => {
            this._getG07List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          }).catch((err) => {
            console.log(err)
            this.$message({
              type: 'error',
              message: '删除失败！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleBatchDeleteClick () {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g07Service.batchDelete(this.selectedValues.join(','))
        .then(res => {
          if (res.data.code === 1) {
            this._getG07List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '操作失败'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败'
          })
        })
    },
    getRowClassName ({ row, rowIndex }) {
      return ''
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.$refs.pictureIndex.getSelectedPicture()) {
        this.$message({
          type: 'warning',
          message: '请选择要绑定的草图！'
        })
        return
      }
      this.$refs.myDialog.close()
      this.doBatchPicture(this.$refs.pictureIndex.getSelectedPicture().pictureGuid)
    },
    doBatchPicture (pictureGuid) {
      g07Service.batchPicture(this.selectedValues, pictureGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getG07List()
            this.$message({
              type: 'success',
              message: (pictureGuid ? '绑定草图' : '删除草图') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (pictureGuid ? '绑定草图' : '删除草图') + '失败！'
          })
        })
    },
    handleBatchLock (isLocked) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g07Service.batchLock(this.planStatExaminationGuid, this.selectedValues, isLocked)
        .then(res => {
          if (res.data.code === 1) {
            this._getG07List()
            this.$message({
              type: 'success',
              message: (isLocked ? '锁定' : '解锁') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (isLocked ? '锁定' : '解锁') + '失败！'
          })
        })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g07Identity', row)
        this._approval(row)
      } else {
        this.g07Model = new G07Model(row)
        this.g07Model.firstYearNum = parseFloat(this.g07Model.firstYearNum)
        this.g07Model.secondYearNum = parseFloat(this.g07Model.secondYearNum)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG07List()
    },
    handleAddClick () {
      if (this.tenderGuid === '0f495580-5be7-11ec-9a75-97d8681ca397') {
        this.$router.push('/planstat/g07')
      } else {
        this.$router.push('/g07')
        // this.$router.push('/finalG07')
      }
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _g07Model = new G07Model(row)
          g07Service.delete(_g07Model.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getG07List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleSelectionChange (newVal) {
      this.selectedValues = newVal
      console.log('selectedValues', this.selectedValues)
    },
    handleSelectAllChange (checkAll) {
      this.g07List.forEach(g07 => {
        g07.checked = checkAll
      })
    },
    _getG07List () {
      this.selectedValues = []
      this.searchModel.planStatExaminationGuid = this.planStatExaminationGuid
      g07Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: '' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.g07List = res.data.data.map(g07 => {
              g07.checked = false
              return g07
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _approval (g07Identity) {
      g07Service.approval(g07Identity.g07Guid, g07Identity.identityGuid, g07Identity.num, g07Identity.sortId, this.planStatExaminationGuid, this.periodName)
        .then(res => {
          if (res.data.code === 1) {
            this._getG07List()
            this.$message({
              type: 'success',
              message: '修改数量成功！'
            })
          } else {
            this._getG07List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this._getG07List()
          this.$message({
            type: 'error',
            message: '修改数量失败！'
          })
        })
    },
    _edit () {
      g07Service.edit(this.g07Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this._getG07List()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getG07List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getG07List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getG07List()
    this.refreshMethod = this._getG07List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  color: red;
}
</style>
