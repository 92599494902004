import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G07Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g07Guid
      this.g07Guid = props.g07Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.meterageListGuid = props.meterageListGuid
      this.firstYearNum = props.firstYearNum
      this.firstYearPrice = props.firstYearPrice
      this.firstYearAmount = props.firstYearAmount
      this.firstYearPercent = props.firstYearPercent
      this.secondYearNum = props.secondYearNum
      this.secondYearPrice = props.secondYearPrice
      this.secondYearAmount = props.secondYearAmount
      this.secondYearPercent = props.secondYearPercent
      this.thirdYearNum = props.thirdYearNum
      this.thirdYearPrice = props.thirdYearPrice
      this.thirdYearAmount = props.thirdYearAmount
      this.thirdYearPercent = props.thirdYearPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g07Guid) this.g07Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.meterageListGuid) this.meterageListGuid = ''
    if (!this.firstYearNum) this.firstYearNum = 0
    if (!this.firstYearPrice) this.firstYearPrice = 0
    if (!this.firstYearAmount) this.firstYearAmount = 0
    if (!this.firstYearPercent) this.firstYearPercent = 0
    if (!this.secondYearNum) this.secondYearNum = 0
    if (!this.secondYearPrice) this.secondYearPrice = 0
    if (!this.secondYearAmount) this.secondYearAmount = 0
    if (!this.secondYearPercent) this.secondYearPercent = 0
    if (!this.thirdYearNum) this.thirdYearNum = 0
    if (!this.thirdYearPrice) this.thirdYearPrice = 0
    if (!this.thirdYearAmount) this.thirdYearAmount = 0
    if (!this.thirdYearPercent) this.thirdYearPercent = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
  }

  generatePrimaryKey () {
    this.g07Guid = utility.getUuid()
    this.resourceId = this.g07Guid
  }
}
