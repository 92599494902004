<template>
  <div class="final-g07-index">
    <my-search-bar>
      <el-form>
        <el-button v-if="showAddBtn" @click="handleAdd">添加</el-button>
        <el-button v-if="showAddBtn" @click="deleteAll">删除所有</el-button>
      </el-form>
    </my-search-bar>
    <br>
    <my-el-table
      v-if="finalG07List.length"
      :data="finalG07List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      keyFiled="finalG07Guid"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
    >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageDisplayCode" width="130" header-align="left" align="left" label="子目号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageName" width="260" header-align="left" align="left" label="子目名称"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="meterageList.price" width="120" header-align="center" align="center" label="单价（元）"></el-table-column>
        <el-table-column prop="meterageList.amount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column v-for="(yearName, index) of actualYearNames" :key="yearName" :label="yearName" header-align="center">
        <el-table-column label="工程量" align="center">
          <template v-slot="scope">
            <span>{{ scope.row.finalG07Details[index].num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="工作量（元）" align="center">
          <template v-slot="scope">
            <span>{{ scope.row.finalG07Details[index].amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="占合同（%）" align="center">
          <template v-slot="scope">
            <span>{{ scope.row.finalG07Details[index].percent }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column v-if="showAddBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-slot="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import { mapGetters } from 'vuex'
import MyElTable from '@/components/MyElTable/MyElTable'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'FinalG07Index',
  mixins: [
    PageOperation
  ],
  components: {
    MySearchBar,
    MyElTable
  },
  data () {
    return {
      finalG07List: [],
      total: 0,
      yearNames: ['第一年度', '第二年度', '第三年度', '第四年度', '第五年度', '第六年度']
    }
  },
  methods: {
    handleAdd () {
      this.$router.push('/finalG07')
    },
    async handleDeleteClick (row) {
      const response = await axios.delete(`${config.restHost}/finalG07/${row.finalG07Guid}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.$message.success('删除成功')
      await this.init()
    },
    async deleteAll () {
      const response = await axios.delete(`${config.restHost}/finalG07/${this.tenderGuid}/deleteAll`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.$message.success('删除成功')
      await this.init()
    },
    async init () {
      this.$loading({})
      const response = await axios.get(`${config.restHost}/finalG07/${this.tenderGuid}`, {
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.total = response.data.total
      this.finalG07List = response.data.list.map(item => ({
        ...item,
        finalG07Details: item.finalG07Details.sort((a, b) => a.year > b.year ? 1 : -1)
      }))
      console.log(this.finalG07List, 'final g07 list')
      this.$loading({}).close()
    }
  },
  computed: {
    actualYearNames () {
      return this.yearNames.filter((_, index) => index < auth.getUserInfo().yearCount)
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  async mounted () {
    await this.init()
    this.refreshMethod = this.init
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
